import { commonJS } from "@pacvue/utils"
import { app } from "@pacvue/frame"
import { PacvueMessageBox } from "@pacvue/element-plus"
import request from "@pacvue/frame/request"
// import { commonJS.goUrl } from "@/utils/common-util"
import { useAnalyticsTrack } from "@pacvue/frame/const"
let productline = localStorage.getItem("productline")
function switchType(name) {
  let v = (name && name.toLocaleLowerCase()) || ""
  let type = ""
  switch (v) {
    case "bulk":
      type = "Bulk"
      break
    case "rule":
      type = "Rule"
      break
    case "ai":
      type = "AI"
      break
    case "dayparting":
      type = "Dayparting"
      break
    case "budget":
      if (productline == "amazon" || productline == "mercado" || productline == "doordash") {
        type = "Budget Manager"
      } else {
        type = "Budget"
      }

      break
    case "budget_scheduler":
      type = "Budget Scheduler"
      break
  }
  return type
}
function gotocheck(row, type, state) {
  let query
  let openLink = ""
  let trackstr = "Message Window"
  if (state == "popover") {
    trackstr = "Message Window"
  } else {
    trackstr = "All Message List"
  }
  if (type === "download" || type === "Data Download") {
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": "Download", "Check Message Source": trackstr })
    // if (productline == "samsclub") {
    //   query = commonJS.encrypt(
    //     JSON.stringify({
    //       caseType: "Data Download"
    //     })
    //   )
    //   window.open(`${path}Message?query=${query}`)
    // } else {
    //   window.open(`${path}Download`)
    // }
    if (productline === "commerce") {
      commonJS.goUrl({ path: "/DownloadCenter" })
    } else {
      commonJS.goUrl({ path: "/Download" })
    }
    return
  }
  if (type === "openDialog") {
    // TODO 打开弹窗并标记为已读
    // dialog.show(JSON.parse(JSON.stringify(row.json)), row.caseType)\
    row.messageState = state
    app.config.globalProperties.$eventHub.emit("openDeatil", row)
    return
  }
  if (type === "warning") {
    PacvueMessageBox({
      title: $t("Alert"),
      message: $t("Your advertiser payment was unsuccessful.Please go to Amazon Seller Center or Vender Center to confirm."),
      pacType: "warning"
    })
    return
  }
  if (type === "/AnomalyAlert") {
    commonJS.goUrl({ path: "/AnomalyAlert" })
    return
  }
  let jsondata = {}
  if (row.messageJson) {
    jsondata = JSON.parse(row.messageJson)
  }
  if (row.eventTitle) {
    jsondata.eventTitle = row.eventTitle
  }
  if (type === "/Campaign/BulkOperationsDetail") {
    let fData
    if (jsondata.constructor === Object) {
      fData = jsondata
    } else {
      fData = jsondata[0]
    }
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": row.sync_source || row.syncSource, "Check Message Source": trackstr })
    commonJS.goUrl({
      path: "/Campaign/BulkOperationsDetail",
      query: {
        id: fData.eventId || 0,
        profileName: fData.profileName || "",
        actionType: fData.actionType || fData.action_type || "",
        profileId: fData.profileId || ""
      }
    })
    return
  }
  if (type === "/event/taskCenter") {
    let fData
    if (jsondata.eventDetail) {
      fData = jsondata.eventDetail
    } else {
      fData = jsondata[0]
      let set = new Set()

      jsondata.forEach((item) => {
        // set.add(`${item.sync_source},${item.effect_level},${item.action_type},${item.sync_source_type}`);
        set.add(`${item.title}`)
      })
      fData.syncSource = fData.sync_source
      fData.eventDateStart = fData.add_date
      fData.eventDateEnd = fData.complete_date
    }
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": switchType(fData.sync_source), "Check Message Source": trackstr })
    commonJS.goUrl({
      path: "/Event/TaskCenter",
      query: {
        profileIds: [jsondata[0].profileId],
        startDate: fData.add_date,
        endDate: fData.complete_dat,
        tabs: switchType(fData.sync_source), //如果压迫跳转到指定的tab就传，不然不需要传
        actionType: fData.title || ""
      }
    })
    return
  }
  if (type === "/CampaignManager/LineItems" || type === "/Campaign/Keyword" || type === "/Campaign/Product") {
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": type, "Check Message Source": trackstr })
    commonJS.goUrl({
      path: type,
      query: {
        targetBidLtMinBid: true
      }
    })
    return
  }
  if (type === "rule/runRecord") {
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": "rule", "Check Message Source": trackstr })
    commonJS.goUrl({
      path: "/Optimization/Rule/RunRecord"
    })
    return
  }
  if (!jsondata.eventDetail) {
    jsondata.eventDetail = jsondata
  }
  if (type === "/event/taskDetail") {
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": "bulk", "Check Message Source": trackstr })
    commonJS.goUrl({
      path: "/event/TaskDetail",
      query: {
        profileIds: [jsondata.eventDetail.profileId],
        startDate: jsondata.eventDetail.eventDateStart,
        endDate: jsondata.eventDetail.eventDateEnd,
        tabs: "bulk",
        effectLevel: jsondata.eventDetail.effectLevel,
        actionEventId: jsondata.eventDetail.actionEventId,
        title: jsondata.eventTitle || "",
        actionType: jsondata.eventDetail.title || ""
      }
    })
    return
  }
  if (type === "/event/taskOtherDetail") {
    if (row.type === "Manual Task") {
      query = {
        profileIds: [jsondata.eventDetail.profileId],
        startDate: jsondata.eventDetail.eventDateStart,
        endDate: jsondata.eventDetail.eventDateEnd,
        tabs: jsondata.eventDetail.syncSource,
        effectLevel: jsondata.eventDetail.effectLevel,
        actionEventId: jsondata.eventDetail.actionEventId,
        actionType: jsondata.eventDetail.title || ""
      }
      useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": jsondata.eventDetail.syncSource, "Check Message Source": trackstr })
    } else {
      if (jsondata.eventDetail.constructor == Object) {
        query = {
          profileIds: [jsondata.eventDetail.profileId],
          startDate: jsondata.eventDetail.eventDateStart,
          endDate: jsondata.eventDetail.eventDateEnd,
          tabs: jsondata.eventDetail.syncSource,
          effectLevel: jsondata.eventDetail.effectLevel,
          actionEventId: jsondata.eventDetail.actionEventId,
          actionType: jsondata.eventDetail.title || ""
        }
        useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": jsondata.eventDetail.syncSource, "Check Message Source": trackstr })
      } else {
        query = {
          profileIds: [jsondata.eventDetail[0].profileId],
          startDate: jsondata.eventDetail[0].add_date,
          endDate: jsondata.eventDetail[0].complete_dat,
          tabs: jsondata.eventDetail[0].sync_source,
          actionType: jsondata.eventDetail[0].title || ""
        }
        useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": jsondata.eventDetail[0].sync_source, "Check Message Source": trackstr })
      }
    }
    commonJS.goUrl({
      path: "/event/TaskOtherDetail",
      query: query
    })
    return
  }
  if (type === "/Advertising/BulkOperation" || type === "Bulk Operation log") {
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": type, "Check Message Source": trackstr })
    let rowMessageJson = JSON.parse(row.messageJson)
    if (row.id && rowMessageJson.filename && rowMessageJson.profileName) {
      commonJS.goUrl({
        path: "/Campaign/BulkOperationsDetail",
        query: {
          profileId: rowMessageJson.id,
          profileName: rowMessageJson.profileName,
          batchId: rowMessageJson.id,
          fileName: rowMessageJson.filename,
          fileLink: rowMessageJson?.fileLink,
          isNew: rowMessageJson?.isNew,
          status: rowMessageJson?.status,
          type: "detail"
        }
      })
    }
    return
  }
  if (type == "/Account/MyAccount") {
    let val = row.detail.includes("Expiration") ? "Expired" : "Invalid"
    useAnalyticsTrack("Check Message", { category: "Experience", "Message Type": "My Account", "Check Message Source": trackstr })
    commonJS.goUrl({ path: type, query: { authorizationStates: val } })
    return
  }
}
function downloadUrl(href) {
  let name = href.split("&fileName=")[1]
  request({
    url: href,
    method: "get",
    responseType: "blob"
  }).then((data) => {
    var downloadElement = document.createElement("a")
    let blob = new Blob([data.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    })
    //   downloadElement.href = href
    downloadElement.style.display = "none"
    downloadElement.href = URL.createObjectURL(blob)
    downloadElement.download = name // 下载后文件名
    document.body.appendChild(downloadElement)
    downloadElement.click() // 点击下载
    document.body.removeChild(downloadElement) // 下载完成移除元素
    window.URL.revokeObjectURL(blob) // 释放掉blob对象
  })
}

export { gotocheck, downloadUrl }
