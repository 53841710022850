import { $t } from "../i18nEntry"

export const indexDBname = () =>
  sessionStorage.getItem("useInfo") && sessionStorage.getItem("useInfo") != "undefined" ? `${JSON.parse(sessionStorage.getItem("useInfo")).userId}_Copilot_Conversation` : null

export const dataFormat = {
  date: "String",
  campaignname: "String",
  weekdayshow: "String",
  impression: "Dateformat",
  click: "Dateformat",
  spend: "Dateformat",
  sales: "Dateformat",
  ctr: "Dateformat",
  acos: "Dateformat",
  roas: "Dateformat",
  cpa: "Dateformat",
  cvr: "Dateformat",
  cpc: "Dateformat",
  totaladsales: "Dateformat",
  paidsov: "Dateformat",
  organicsov: "Dateformat",
  sbproductsov: "Dateformat",
  sbvsov: "Dateformat",
  shareofshelf: "Dateformat",
  sbsov: "Dateformat",
  spsov: "Dateformat",
  orders: "Dateformat",
  saleunits: "Dateformat"
}

export const nameArr = [
  "KeywordText",
  "ASIN",
  "Asin",
  "query",
  "targetText",
  "AdgroupName",
  "CampaignName",
  "CampaignType",
  "CampaignTagName",
  "AdGroupTagName",
  "AsinTagName",
  "KeywordTagName",
  "PortfolioName",
  "Brand",
  "VendorGroupName",
  "ProfileName",
  "SOVGroup"
]

export const SpMap = {
  tacos: "acos",
  troas: "roas",
  "t-acos": "acos",
  "t-roas": "roas",
  organicrevenue: "sales",
  totalrevenue: "sales",
  revenue: "sales",
  totaladsales: "sales",
  totalmediasales: "sales",
  totalcvr: "cvr"
}

export const insightPage = {
  Campaign: "Campaign",
  CampaignAdGroup: "Adgroup",
  Keyword: "Keyword",
  PAT: "PAT",
  CampaignAsin: "ASIN",
  CampaignProfile: "Profile",
  CampaignTagging: "CampaignTag",
  KeywordTagging: "KeywordTag",
  AsinTag: "AsinTag",
  "Product Listing": "ProductCenter-Asin",
  "SOV Brand": "SOV"
}

export const colors = ["#9B88FA", "#FFB268", "#75BDFF", "#FF9B9E", "#8FE4F0", "#6585F3", "#C2F372", "#6ADFA7", "#299AFF", "#DE9DF4", "#28DAC6", "#F0E360", "#F5AAD7", "#97E7D3", "#FFDF6F", "#00CFE8"]

// Record<String, Map<Array<RegExp>, Array<String>>>
export const PAGE_QUESTIONS_PRESETS = {
  amazon: new Map([
    [{ path: /^\/Home.*/, name: "Home" }, [$t("What does each widget represent in Home module?"), $t("How to customize the widgets in Home module?"), $t("How to share the Home page link?")]],
    [{ path: /^\/ProductCenter\/ProductDashboard.*/, name: "ProductDashboard" }, [$t("What is Product Center used for?")]],
    [
      { path: /^\/ProductCenter\/ProductListing.*/, name: "Product Listing" },
      [$t("What is Product Center used for?"), $t("How to view the performance of a specific ASIN in Product Center?"), $t("How to add ASIN to an adgroup in Product Center?")]
    ],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/Campaign\/CampaignTagging.*/, name: "CampaignTagging" }, [$t("What is a campaign tag used for?"), $t("How to add campaigns to a campaign tag?")]],
    [{ path: /^\/Campaign\/KeywordTagging.*/, name: "KeywordTagging" }, [$t("What is a keyword tag used for?"), $t("How to add keywords to a keyword tag?")]],
    [{ path: /^\/Campaign\/ASINTagging.*/, name: "AsinTag" }, [$t("What is an ASIN tag used for?"), $t("How to add ASIN to an ASIN tag?")]],
    [
      { path: /^\/Campaign\/Campaign.*/, name: "Campaign" },
      [
        $t("How to create sp campaign?"),
        $t("What is Sponsored Products Super Wizard?"),
        $t("How to use automation function to optimize a campaign?"),
        $t("How to view the performance of a specific campaign?")
      ]
    ],
    [{ path: /^\/Campaign\/Keyword.*/, name: "Targeting" }, [$t("How to add keywords to an adgroup?"), $t("How to view the performance of a specific keyword?")]],
    [{ path: /^\/Campaign\/Asin.*/, name: "CampaignAsin" }, [$t("How to add ASIN to an adgroup in ASIN module?"), $t("How to view the performance of a specific ASIN?")]],
    [{ path: /^\/Campaign\/Query.*/, name: "CampaignSearchTerm" }, [$t("How to add queries as keywords to an adgroup?"), $t("How to view the performance of a specific query?")]],
    [{ path: /^\/Report\/Index.*/i, name: "Default Report" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport.*/, name: "My Report" }, [$t("DSPkey3504")]],
    [{ path: /^\/Research\/KeywordsCopy.*/, name: "Keyword Research" }, [$t("What is Keyword Research used for?"), $t("What is the difference between three research types?")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/Optimization\/Dayparting.*/, name: "Dayparting Scheduler" }, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
    [{ path: /^\/Optimization\/Schedule.*/, name: "Budget Schedule" }, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
    [{ path: /^\/Optimization\/AutomationAI.*/, name: "Campaign AI" }, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
    [{ path: /^\/Optimization\/ASINAI.*/, name: "Product AI" }, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
    [{ path: /^\/AuditCenter.*/, name: "Paid Search Audit" }, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
    [{ path: /^\/Optimization\/LiveMomentum.*/, name: "War Room" }, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
    [{ path: /^\/Optimization\/BidExplorer.*/, name: "Bidexplorer" }, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]],
    [{ path: /^\/Analysis.*/, name: "Competitive" }, [$t("What is Competitor Analysis used for?"), $t("How to monitor keywords?"), $t("How to view the sov result of a monitored keyword?")]],
    [{ path: /^\/CategoryIntelligence\/SearchTerm.*/, name: "SearchTerm" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/Product.*/, name: "Product" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/BrandAndSales.*/, name: "BrandAndSales" }, [$t("What is Category Intelligence used for?")]]
    // [{ path: /^\/Permission\/UserManage.*/, name: "User Management" }, [$t("key3048"), $t("key3049")]]
  ]),
  dsp: new Map([
    [{ path: /^\/Dashboard.*/, name: "Dashboard" }, [$t("DSPkey3464"), $t("DSPkey3465")]],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/CampaignManager\/Audience.*/, name: "Audience" }, [$t("DSPkey3484"), $t("DSPkey3485"), $t("DSPkey3486")]],
    [{ path: /^\/CampaignManager\/A.*/, name: "Advertiser" }, [$t("DSPkey3469"), $t("DSPkey3470")]],
    [{ path: /^\/CampaignManager\/Orders.*/, name: "Orders" }, [$t("DSPkey3471"), $t("DSPkey3472"), $t("DSPkey3473"), $t("DSPkey3474")]],
    [{ path: /^\/CampaignManager\/LineItems.*/, name: "LineItems" }, [$t("DSPkey3477"), $t("DSPkey3478")]],
    [{ path: /^\/CampaignManager\/Creative.*/, name: "Creative" }, [$t("DSPkey3479"), $t("DSPkey3480"), $t("DSPkey3481"), $t("DSPkey3482"), $t("DSPkey3483")]],
    [{ path: /^\/CampaignManager\/Product.*/, name: "Product" }, [$t("DSPkey3487"), $t("DSPkey3488"), $t("DSPkey3483"), $t("DSPkey3490")]],
    [{ path: /^\/CampaignManager\/OffAmazon.*/, name: "OffAmazon" }, [$t("DSPkey3491"), $t("DSPkey3492"), $t("DSPkey3493")]],
    [{ path: /^\/Tag\/OrderTag.*/, name: "OrderTag" }, [$t("DSPkey3494"), $t("DSPkey3495"), $t("DSPkey3496")]],
    [{ path: /^\/Tag\/LineTag.*/, name: "LineTag" }, [$t("DSPkey3497"), $t("DSPkey3498"), $t("DSPkey3499")]],
    [{ path: /^\/Tag\/CreativeTag.*/, name: "CreativeTag" }, [$t("DSPkey3500"), $t("DSPkey3501"), $t("DSPkey3502")]],
    [{ path: /^\/AudienceResearch.*/, name: "AudienceLibrary" }, [$t("DSPkey3505"), $t("DSPkey3506"), $t("DSPkey3507")]],
    [{ path: /^\/Research\/CreativeInsight.*/, name: "CreativeInsight" }, [$t("DSPkey3508")]],
    [{ path: /^\/Report\/DefaultReport.*/, name: "DefaultReport" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport.*/, name: "MyReport" }, [$t("DSPkey3504")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/AI.*/, name: "AI" }, [$t("DSPkey3514"), $t("DSPkey3515"), $t("DSPkey3516"), $t("DSPkey3517")]],
    [{ path: /^\/Audit.*/, name: "AuditCenter" }, [$t("DSPkey3518"), $t("DSPkey3519")]]
  ]),
  walmart: new Map([
    [{ path: /^\/Home.*/, name: "Home" }, [$t("What does each widget represent in Home module?"), $t("How to customize the widgets in Home module?"), $t("How to share the Home page link?")]],
    [{ path: /^\/ProductCenter\/ProductDashboard.*/, name: "ProductDashboard" }, [$t("What is Product Center used for?")]],
    [
      { path: /^\/ProductCenter\/ProductListing.*/, name: "Product Listing" },
      [$t("What is Product Center used for?"), $t("How to view the performance of a specific item in Product Center?"), $t("How to add items to an adgroup in Product Center?")]
    ],
    [{ path: /^\/Budget\/BudgetDashboard.*/, name: "Budget Manager" }, [$t("amskey3264"), $t("amskey3265"), $t("amskey3266")]],
    [{ path: /^\/Campaign\/CampaignTagging.*/, name: "CampaignTagging" }, [$t("What is a campaign tag used for?"), $t("How to add campaigns to a campaign tag?")]],
    [{ path: /^\/Campaign\/KeywordTagging.*/, name: "KeywordTagging" }, [$t("What is a keyword tag used for?"), $t("How to add keywords to a keyword tag?")]],
    [{ path: /^\/Campaign\/ItemTagging.*/, name: "ItemTagging" }, [$t("What is an item tag used for?"), $t("How to add items to an item tag?")]],
    [
      { path: /^\/Campaign\/Campaign.*/, name: "Campaign" },
      [$t("How to create sp campaign?"), $t("How to use automation function to optimize a campaign?"), $t("How to view the performance of a specific campaign?")]
    ],
    [{ path: /^\/Campaign\/Keyword.*/, name: "Targeting" }, [$t("How to add keywords to an adgroup?"), $t("How to view the performance of a specific keyword?")]],
    [{ path: /^\/Campaign\/Item.*/, name: "AdvertisingItem" }, [$t("How to add items to an adgroup?"), $t("How to view the performance of a specific item in Item module?")]],
    [{ path: /^\/Campaign\/Query.*/, name: "AdvertisingSearchTerm" }, [$t("How to add queries as keywords to an adgroup?"), $t("How to view the performance of a specific query?")]],
    // [{ path: /^\/Campaign\/BulkOperations.*/, name: "AdvertisingBulkOperations" }, [$t("key3028"), $t("key3029"), $t("key3030")]],
    [{ path: /^\/Report\/index.*/i, name: "Default Report" }, [$t("DSPkey3503")]],
    [{ path: /^\/Report\/MyReport/, name: "My Report" }, [$t("DSPkey3504")]],
    [{ path: /^\/Research\/KeywordsCopy.*/, name: "Keyword Research" }, [$t("What is Keyword Research used for?"), $t("What is the difference between three research types?")]],
    [{ path: /^\/Research\/ImprShare.*/, name: "Impr Share" }, [$t("What is Impr. Share used for?")]],
    [{ path: /^\/Optimization\/QueryAsinGraph.*/, name: "Query-Item Graph" }, [$t("What is Query-Item Graph used for?")]],
    [{ path: /^\/Optimization\/Rule.*/, name: "Rule" }, [$t("amskey3267"), $t("amskey3268"), $t("amskey3269"), $t("amskey3270"), $t("amskey3271")]],
    [{ path: /^\/Optimization\/Dayparting.*/, name: "Dayparting Scheduler" }, [$t("amskey3272"), $t("amskey3273"), $t("amskey3274")]],
    [{ path: /^\/Optimization\/budgetschedule.*/, name: "Budget Scheduler" }, [$t("amskey3275"), $t("amskey3276"), $t("amskey3277")]],
    [{ path: /^\/Optimization\/AutomationAI.*/, name: "Campaign AI" }, [$t("amskey3278"), $t("amskey3279"), $t("amskey3280"), $t("amskey3281"), $t("amskey3282")]],
    [{ path: /^\/Optimization\/ASINAI.*/, name: "Product AI" }, [$t("amskey3283"), $t("amskey3284"), $t("amskey3285"), $t("amskey3286")]],
    [{ path: /^\/AuditCenter.*/, name: "Paid Search Audit" }, [$t("amskey3287"), $t("amskey3288"), $t("amskey3289")]],
    [{ path: /^\/Optimization\/LiveMomentum.*/, name: "War Room" }, [$t("amskey3290"), $t("amskey3291"), $t("amskey3292")]],
    [{ path: /^\/Optimization\/BidExplorer.*/, name: "Bidexplorer" }, [$t("amskey3293"), $t("amskey3294"), $t("amskey3295")]],
    [{ path: /^\/Analysis.*/, name: "Competitive" }, [$t("What is Competitor Analysis used for?"), $t("How to monitor keywords?"), $t("How to view the sov result of a monitored keyword?")]],
    [{ path: /^\/CategoryIntelligence\/SearchTerm.*/, name: "SearchTerm" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/Product.*/, name: "Product" }, [$t("What is Category Intelligence used for?")]],
    [{ path: /^\/CategoryIntelligence\/BrandAndSales.*/, name: "BrandAndSales" }, [$t("What is Category Intelligence used for?")]]
    // [{ path: /^\/Account\/MyAccount.*/, name: "My Account" }, [$t("key3045"), $t("key3046"), $t("key3047")]],
    // [{ path: /^\/Permission\/UserManage.*/, name: "User Management" }, [$t("key3048"), $t("key3049")]],
    // [{ path: /\/ShareLink/, name: "Share Link" }, [$t("key3050"), $t("key3051")]]
  ])
}
