const whiteLabelMap = new Map([
  [
    ["groupm.pacvue"],
    {
      isThemeFixed: true,
      themeColors: {
        themeColor: "#0014CC",
        themeBG3: "#0A2FFF",
        themeBG5: "#85A3FF",
        themeBG8: "#C2D4FF",
        themeBG9: "#E0EBFF",
        themeFSC: "#FDE212",
        themeRMH: "#fde212", // Menu 高亮色
        themeRMHT: "#000785", // Menu 高亮色中的文字色
        themeTHC: "#F0F7FF", // Table 专用 Hover 色
        themeRGB: "0, 20, 204"
      },
      globalLoadingSVG: "GroupM",
      logoPNG: "groupm.pacvue.com",
      tinyLogoPNG: "groupm.pacvue.com",
      favTitle: "GroupM",
      favIcon: "https://www.groupm.com/wp-content/themes/bigdrop-theme/dist/images/favicon-32x32.png",
      topBarTheme: "white",
      topBarBgColor: "#fff",
      topBarTextColor: "#45464f",
      sideBarBgColor: "#000050",
      sideBarBgSubColor: "#000333",
      homeTopCardBgColors: ["#85a3ff", "#6ce07b", "#ecc706"]
    }
  ]
])

const whiteLabelsConfig = []
const iter = whiteLabelMap.entries()
let entry
// 访问直至迭代器 Exhausted
while ((entry = iter.next().value) !== undefined) {
  whiteLabelsConfig.push(entry)
}

// 独立白名单站点 (无左上角跳转)
const independentHosts = ["commerce.lungemarketing.com"]

export { whiteLabelsConfig, independentHosts }
