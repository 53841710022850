import { app } from "../src/frame"
import { PAGE_QUESTIONS_PRESETS } from "../src/Copilot/config"

const getProductLine = () => window.productline || localStorage.getItem("productline")

const judgeActiveCopilotGuidingPopByRoute = () => {
  const route = app.config.globalProperties.router.currentRoute.value
  for (const entry of PAGE_QUESTIONS_PRESETS[getProductLine()]) {
    if (route.name === entry[0].name || entry[0].path.test(route.path)) {
      return true
    }
  }
  return false
}

const getCopilotQuestionsPresetsByRoute = () => {
  const route = app.config.globalProperties.router.currentRoute.value
  for (const entry of PAGE_QUESTIONS_PRESETS[getProductLine()]) {
    if (route.name === entry[0].name || entry[0].path.test(route.path)) {
      return entry[1]
    }
  }
  return []
}

export { judgeActiveCopilotGuidingPopByRoute, getCopilotQuestionsPresetsByRoute }
